import React, { useContext, useEffect, useState } from 'react'
import { SERVER_API } from 'src/config'
import { useLocation } from 'react-router-dom'

export interface UserAndAccess {
  id: string
  email: string
  fullName: string
  accessToken: string
}

type AuthContextType = {
  user: UserAndAccess | null;
  signOut: () => void;
};

export const AuthContext = React.createContext<AuthContextType | null>(null)

export interface AuthProps {
  children: React.ReactNode
}

/**
 * React Context for user authentication.
 * It guaranties that the API call will happen only once
 * without extra React components re-rendering.
 *
 * @param param - child component.
 * @returns
 */
export function AuthProvider({ children }: AuthProps) {

  const [user, setUser] = useState<UserAndAccess | null>(null)
  const { pathname } = useLocation();

  useEffect(() => {
    async function authenticateMe() {
      try {
        const res = await fetch(SERVER_API + '/auth/me', {
          credentials: 'include',
        })
        const data = await res.json()
        if (typeof data?.redirect === 'string') {
          //public page don't need to sign in
          const parts = pathname.split('/');
          console.log(`parts[1]: ${parts[1]}`)
          if (parts[1] !== "scan") {
            window.location.href = data.redirect
          }
        } else {
          return data as UserAndAccess
        }
      } catch (e) {
        console.log('authentication error.')
      }
    }

    authenticateMe().then((jwt: UserAndAccess | undefined) => {
      if (jwt) {
        setUser({
          id: jwt.id.toString(),
          fullName: jwt.fullName,
          email: jwt.email,
          accessToken: jwt.accessToken
        })
      }
    })
  }, [pathname])

  const signOut = async () => {
    setUser(null);
    try {
      await fetch(SERVER_API + '/auth/logout', {
        method: 'POST',
        credentials: 'include',
      })
    }
    catch (e) {
      console.log('clear cookie error.')
    }
  };

  return <AuthContext.Provider value={{ user, signOut }}>
    {children}
  </AuthContext.Provider>
}

/**
 * @returns
 */
export function useAuthMe() {
  return useContext(AuthContext)!
}
