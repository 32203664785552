import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client'
import { I18nextProvider } from "react-i18next"
import { i18n } from 'shared-localization'
import { Toaster } from './components/shadcnUI/toaster'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from 'apollo-link-context'
import { createClient } from 'graphql-ws'
import { Suspense, lazy, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { SERVER_API, SERVER_WS_API } from './config'
import { useAuthMe } from './authProvider'
import './main.css'

const MainPage = {
  Layout: lazy(() => import('./pages/AppLayout')),
  Scans: lazy(() => import('./pages/Scans')),
  Explore: lazy(() => import('./pages/Explore')),
  ScanDetail: lazy(() => import('./pages/ScanDetail')),
  Account: lazy(() => import('./pages/AccountPage')),
  Scan: lazy(() => import('./pages/PublicScanDetail')),
}

/**
 * Renders the scans of current users
 */
function ScansApp() {
  return (
    <Routes>
      <Route path="/scan/:content"
        element={<Suspense fallback={<></>}>
          <MainPage.Scan />
        </Suspense>}>
      </Route>

      <Route
        path="/"
        element={
          <Suspense fallback={<></>}>
            <MainPage.Layout />
          </Suspense>
        }
      >
        <Route
          index
          element={
            <Suspense fallback={<></>}>
              <MainPage.Scans />
            </Suspense>
          }
        />
        <Route
          path="scans"
          element={
            <Suspense fallback={<></>}>
              <MainPage.Scans />
            </Suspense>
          }
        />
        <Route
          path="explore"
          element={
            <Suspense fallback={<></>}>
              <MainPage.Explore />
            </Suspense>
          }
        />
        <Route
          path="scandetail"
          element={
            <Suspense fallback={<></>}>
              <MainPage.ScanDetail />
            </Suspense>
          }
        />
        <Route
          path="account"
          element={
            <Suspense fallback={<></>}>
              <MainPage.Account />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

function App() {

  const { user } = useAuthMe()

  const client = useMemo(() => {
    const wsLink = new GraphQLWsLink(
      createClient({
        url: SERVER_WS_API + '/graphql',
        lazy: true,
        connectionParams: async () => {        
          const accessToken = user?.accessToken
          return {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        },
      }))

    const autLink = setContext(async (req, { headers }) => {
      const accessToken = user?.accessToken
      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    })

    const httpLink = autLink.concat(
      createHttpLink({
        uri: SERVER_API + '/graphql',
      }) as any // conflict between the same ApolloLink type from 2 different libraries.
    ) as any

    const link = split(
      ({ query }) => {
        const def = getMainDefinition(query)
        return (
          def.kind === 'OperationDefinition' &&
          (def as any).operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )

    return new ApolloClient({
      link: link,
      cache: new InMemoryCache(),
    })
  }, [user])

  return <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <ScansApp />
        </I18nextProvider>
        <Toaster />
      </ApolloProvider>   
}

export default App