// packages/shared-localization/localization.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
  debug: true,
  lng: "en",
  fallbackLng: "en", // use en if detected lng is not available

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  },
  resources: {
    en: {
      translations: require('./translations/en.json'),
    },
    ch: {
      translations: require('./translations/ch.json'),
    },
  },
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations"
});

export default i18n;
