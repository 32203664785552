import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Test cases
// console.log(isHttpOrHttpsLink('https://www.example.com')); // true
// console.log(isHttpOrHttpsLink('http://www.example.com'));  // true
// console.log(isHttpOrHttpsLink('ftp://www.example.com'));   // false
// console.log(isHttpOrHttpsLink('www.example.com'));         // false

export function isHttpOrHttpsLink(str : any) {
  if (!str || typeof str !== 'string') {
    return false; // Not a string, so not a valid URL format
  }
  // Regular expression pattern to match HTTP and HTTPS URLs
  const urlPattern = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/i;

  // Test the string against the pattern
  return urlPattern.test(str);
}